
 *{
  font-family: Rubik, sans-serif!important;
}

/* * {
  margin: 0;
  padding: 0;
 
} */



form,
input,
select,
textarea,
label {
  font-family: inherit;
} 

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.9);
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  /* display: none; */
}
.allow-scroll {
  scrollbar-width: thin;
}

 .allow-scroll::-webkit-scrollbar {
  display: initial;
} 
 .table.item-table :is(th, td):last-child {
  text-align: center;
  width: 2rem;
}

@media(max-width:768px) {
	::-webkit-scrollbar {
		width: 4px;
		height: 1px;
	}
}


